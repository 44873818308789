<template>
  <Layout>
    <template v-if="this.$route.name == 'add-expert'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard @on-complete="submitData" color="#556ee6" enctype="multipart/form-data" ref="expert"
              :finishButtonText="this.$route.name == 'add-expert' ? 'Save Data' : 'Update Data'"
              back-button-text="Go Back!" next-button-text="Go Next!">
              <tab-content title="Expert Details" icon="mdi mdi-account-details"
                :before-change="() => validateFormOne()">
                <div class="row">
                  <b-form-group class="col-6">
                    <label for="input-2">Expert Name <span style="color: red">*</span></label>
                    <b-form-input id="input-2" v-model="form.name" placeholder="Enter Expert Name" :class="{
                      'is-invalid': submitted && $v.form.name.$invalid,
                    }"></b-form-input>
                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                      Expert Name is required.
                    </div>
                  </b-form-group>
                  <b-form-group label="Expert Type" label-for="experttype" class="col-6">
                    <multiselect id="experttype" v-model="form.type" :options="experttype" 
                      track-by="value" label="name" style="margin-top: 8px" >
                    </multiselect>
                  </b-form-group>
                </div>

                <div class="row">
                  <b-form-group class="col-4 position-relative" id="input-group-8" label="Thumbnail URL"
                    label-for="input-8">
                    <b-form-input id="input-8" :maxlength="250" v-model="form.thumbnail_url"
                      placeholder="Enter Thumbnail URL">
                    </b-form-input>
                    <div class="text-right">
                      <p v-if="form.thumbnail_url" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.thumbnail_url.length !== 250,
                        'badge-danger': form.thumbnail_url.length === 250
                      }">
                        You typed
                        {{ form.thumbnail_url.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-3" class="col-4">
                    <label for="input-1">Expert Display Tag/Name</label>
                    <b-form-input id="input-1" v-model="form.display_name" placeholder="Enter Email"></b-form-input>

                  </b-form-group>
                  <b-form-group id="input-group-3" class="col-4">
                    <label for="input-1">Email</label>
                    <b-form-input id="input-1" v-model="form.email" placeholder="Enter Email"></b-form-input>
                    <div v-if="!$v.form.email.email" class="error">
                      <span>Please Enter Valid Email.</span>
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-3" class="col-6">
                    <label for="input-1">Mobile Number</label>
                    <b-form-input id="input-1" v-model="form.mobile_number" type="number" placeholder="Enter Mobile number"></b-form-input>
                    <div v-if="!$v.form.mobile_number" class="error">
                      <span>Please Enter Valid Mobile Number.</span>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6 position-relative" id="input-group-4" label="Designation"
                    label-for="input-4">
                    <b-form-input :maxlength="250" id="input-4" v-model="form.designation"
                      placeholder="Enter Designation">
                    </b-form-input>
                    <div class="text-right">
                      <p v-if="form.designation" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.designation.length !== 250,
                        'badge-danger': form.designation.length === 250
                      }">
                        You typed
                        {{ form.designation.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-5" label="Working At" label-for="input-5" class="col-6">
                    <b-form-input id="input-5" v-model="form.working_at" placeholder="Enter Working At"></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-20" label="Thumbnail Image [Upload Max File Size : 2MB]"
                    label-for="input-20" class="col-6">
                    <b-form-file id="input-20" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'image_name')" ref="image_name"></b-form-file>
                    <template v-if="$route.name == 'edit-expert' && edit.image_name_url">
                      <img :src="edit.image_name_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="image_name_url">
                      <img :src="image_name_url" width="128px" height="128px" ref="image_name_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                  <b-form-group id="input-group-6" label="Area of Interest" label-for="input-6" class="col-6">
                    <b-form-textarea id="input-6" v-model="form.areas_of_interest" rows="3" max-rows="6">
                    </b-form-textarea>
                  </b-form-group>
                  <b-form-group label-for="member_tagging" class="col-6">
                    <div
                      class="my-1 d-flex justify-content-between align-items-center"
                    >
                      <label label-for="in-forum" class="mb-1">Member Tagging</label>
                      <div
                        @click="removeMemberOption"
                        style="cursor: pointer"
                        v-if="form.member_tagging != null"
                      >
                        <img
                          src="@/assets/images/close.png"
                          height="12px"
                        />
                      </div>
                    </div>
                    <multiselect v-model='form.member_tagging' :options="memberList" :multiple="false" id="member_tagging"
                      track-by="member_id" label="fnameAndLname" placeholder="Type here to search" @search-change="fetchMemberList">
                        <span slot="noOptions">
                          Type here to search
                        </span>
                    </multiselect>
                  </b-form-group>
                </div>

                <b-form-group id="input-group-7" label="Tell Us More About The Expert" label-for="input-7">
                  <ckeditor v-model="form.description"></ckeditor>
                </b-form-group>
              </tab-content>

              <tab-content title="Add Tags" icon="fa fa-tag" :before-change="() => validateFormTwo()">
                <div class="row">
                  <b-form-group class="col-6">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <label for="comm" class="mb-0">Community <span style="color: red;">*</span></label>
                      <b-form-checkbox v-model="status" button button-variant="info" size="sm">
                        <template v-if="status">Unselect All</template>
                        <template v-else>Select All</template>
                      </b-form-checkbox>
                    </div>
                    <multiselect @search-change="fetchCommunity" id="comm" v-model="form.community_selected"
                      :options="community" :multiple="true" track-by="id" label="title" :class="{
                        'is-invalid':
                          store && $v.form.community_selected.$invalid,
                      }">
                      <span slot="noOptions">
                        Type here to search
                      </span>
                    </multiselect>
                    <div v-if="store && !$v.form.community_selected.required" class="invalid-feedback">
                      Community is required.
                    </div>
                  </b-form-group>
                  <b-form-group label="Sub Speciality" label-for="subspe" class="col-6">
                    <multiselect id="subspe" v-model="form.sub_specialities" :options="subspeciality" :multiple="true"
                      track-by="id" label="name" style="margin-top: 15px">
                      <span slot="noOptions">
                        Type here to search
                      </span>
                    </multiselect>
                  </b-form-group>
                  <b-form-group class="col-4">
                    <label for="natio">Nationality <span style="color: red">*</span></label>
                    <b-form-select id="natio" v-model="form.nationality" :options="country" value-field="name"
                      text-field="name" :class="{
                        'is-invalid': store && $v.form.nationality.$invalid,
                      }">
                    </b-form-select>
                    <div v-if="store && !$v.form.nationality.required" class="invalid-feedback">
                      Nationality is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-4 position-relative" id="input-group-9" label="Meta Title"
                    label-for="input-9">
                    <b-form-input :maxlength="190" id="input-9" v-model="form.meta_title"
                      placeholder="Enter Meta Title"></b-form-input>
                    <div class="text-right">
                      <p v-if="form.meta_title" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.meta_title.length !== 190,
                        'badge-danger': form.meta_title.length === 190
                      }">
                        You typed
                        {{ form.meta_title.length }} out of 190 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-4 position-relative" id="input-group-11" label="Meta Keywords"
                    label-for="input-11">
                    <b-form-input id="input-11" :maxlength="250" v-model="form.meta_keywords"
                      placeholder="Enter Meta Keywords">
                    </b-form-input>
                    <div class="text-right">
                      <p v-if="form.meta_keywords" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.meta_keywords.length !== 250,
                        'badge-danger': form.meta_keywords.length === 250
                      }">
                        You typed
                        {{ form.meta_keywords.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-12 position-relative" label="Meta Description" label-for="input-10">
                    <b-form-textarea id="textarea" :maxlength="400" v-model="form.meta_description"
                      placeholder="Enter Meta Description" rows="3"></b-form-textarea>
                    <div class="text-right">
                      <p v-if="form.meta_description" class="badge position-absolute" style="top: 4px; right: 13px"
                        :class="{
                          'badge-success': form.meta_description.length !== 400,
                          'badge-danger': form.meta_description.length === 400
                        }">
                        You typed
                        {{ form.meta_description.length }} out of 400 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-13" class="col-12">
                    <div style="display: flex; gap: 10px">
                      <b-form-checkbox v-model="form.show_in_home">Show in home page</b-form-checkbox>
                      <b-form-checkbox v-model="form.visible_in_cases">Visible in cases</b-form-checkbox>
                    </div>
                  </b-form-group>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import expertMixin from "../../../mixins/ModuleJs/expert";
import Multiselect from "vue-multiselect";
import { required, email } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
  mixins: [MixinRequest, expertMixin],
  data() {
    return {
      submitted: false,
      store: false,
      title1: "Add Expert",
      title2: "Edit Expert",
      items: [
        {
          text: "List",
          href: "/expert",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      community_selected: { required },
      name: { required },
      nationality: { required },
      email: { email },
      mobile_number: { required }
    },
  },
  components: {
    PageHeader,
    Layout,
    Multiselect,
    FormWizard,
    TabContent,
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.name.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {
      this.store = true;
      if (this.$v.form.community_selected.$invalid || this.$v.form.nationality.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
  },
};
</script>
